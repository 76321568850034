//Font Size
$lite: 14px;
$small: 15px;
$fs-medium: 16px;
$large: 18px;
$xlarge: 20px;
$xxlarge: 22px;
$xxxlarge: 24px;
$xxxmedium:35px;
$xxxxlarge: 40px;

//Font-Weights
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

