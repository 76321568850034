// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo{
    display: none;
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
    

    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{
        // background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

.profile-screen{

    .user-info{
        .card{
            height: 100%;
        }
    }
}

// new style
.account-pages {
    background:  $whiteColor;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    .login-bg {
        background: linear-gradient(186deg, #059653 -23%, #77CE46 114.62%);
        height: 100%;
        padding: 100px;
        position: relative;
        .logo{
            img{
                filter: brightness(0) invert(1);
            }
        }
        .welcome-content {
            position: absolute;
            bottom: 100px;

            h1 {
                color: $white;
                font-size: $xxxxlarge;
                font-weight: $semibold;
            }

            p {
                color: $white;
                font-size: $xlarge;
                max-width: 450px;
                margin: 0;
            }
        }
    }

    .container {
        overflow: hidden;
    }
    .error-img {
        text-align: center;

        img {
            max-width: 460px;
        }
    }
    .d-grid {
        display: grid !important;
        width: 70%;
        margin: 0 auto;
    }
    .logo {
        line-height: 0;
    }
}
.welcome-form{
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    form{
        padding-top: 20px;
    }
    .form-control{
        // border: 1px solid red;
        // margin-bottom: 20px;
        
    }
    .auth-pass-inputgroup{
        .form-control{
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
            &::placeholder{
                position: relative;
                top: 2px;
            }
        }
    }
    .show-password{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 1px;
        right: 1px;
        left: auto;
        z-index: 5;
        #password-addon{
            background: #e9dbdb;
            border: 0;
            padding: 13px 12px;
            border-radius: 0 10px 10px 0;
        }
        .mdi{
            font-size: $fs-medium;
            color: $dark-grey;
            opacity: 0.6;
        }
    }
    .primary-button{
        margin-top: 30px;
    }
    .forgot-password{
       a{
        color: $dark-grey;
        text-decoration: underline !important;
       }
    }
    .primary-button{
        min-height: 50px;
    }
}