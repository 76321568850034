//Border-Radius
@mixin borderRadius_5 {  
    border-radius: 5px;  
} 
@mixin borderRadius_10 {  
    border-radius: 10px;  
} 
@mixin borderRadius_15 {  
    border-radius: 15px;  
} 
@mixin borderRadius_30 {  
    border-radius: 30px;  
} 
@mixin borderRadius_50 {  
    border-radius: 50px;  
} 

//Flex 
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-column {
    @include flex-center;
    flex-direction: column;
}

@mixin flex-center-vert {
    display: flex;
    align-items: center;
}

@mixin flex-center-horiz {
    display: flex;
    justify-content: center;
}

@mixin justify-between{
    @include flex-center-vert;
    justify-content: space-between;
}

// Box-width
@mixin box-size($width, $height: $width) {
    width: $width;
    height: $height;
}