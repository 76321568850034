// 
// backgrounds.scss
//


@each $color, $value in $theme-colors {
    .bg-#{$color} {
        &.bg-soft{
            background-color: $light-primary !important;
        }
    }
}
