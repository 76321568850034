.theme-btn {
    height: 30px;
    position: relative;

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 60px;
        height: 30px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 28px;
        height: 22px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s ease-in-out;
    }

    input:checked+label {
        background: $green;
    }

    input:checked+label:after {
        left: calc(100% - 4px);
        transform: translateX(-100%);
    }

    i{
       position: absolute;
       top: 3px;
       left: 8px;
       font-size: 16px;
       display: block;
    }
    i.dark{
        left: auto;
        right: 10px; 
    } 
   
    label:active:after {
        width: 58px;
    }
}