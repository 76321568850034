.manage-tip {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 20px;
  padding-bottom: 30px;

  .closed-text {
    display: none;
  }

  .closed-tip {
    position: relative;

    .closed-text {
      display: block;

      p {
        position: absolute;
        top: 45%;
        left: 42%;
        font-size: x-large;
        z-index: 1;
        margin-bottom: 0;
        font-weight: 500;
        background: #fff;
        font-size: 18px;
        padding: 5px 15px;
        border-radius: 8px;
      }
    }

    .card {
      background: $closed-color;
    }

    .not-achieved {
      border-left: 4px solid $error-color;
    }

    .achieved {
      border-left: 4px solid $sucess-color;
    }
  }
}

.tip-content {
  position: relative;

  .blog-header {
    ul {
      @include justify-between;

      li {
        margin-right: 15px
      }
    }

    @include justify-between;

    .genius-talk-heading {
      .updated-badge {
        display: none;
      }
    }
  }

  .tips-auth-info {
    padding-top: 10px;
    width: 60%;
    @include justify-between;

    .btn-common {
      height: 35px;
    }
  }

  .badge {
    padding: 0;

    .stock-tiptype {
      font-size: $lite;
      font-weight: $medium;
      border-radius: 30px;
      color: $white;
      padding: 5px 10px;
      text-transform: capitalize;

      &.sell {
        background-color: $sellbgColor;
      }

      &.buy {
        background-color: $buybgColor;
      }

      &.market_info {
        background-color: $yellow;
      }

      &.valuable_ideas {
        background-color: $blue;
      }
    }
  }

  .tips-auth-info {
    .ad-profile {
      @include box-size(30px, 30px);
      object-fit: cover;
      margin-right: 8px;
      @include flex-center;
      background: #f2f5f7;
      overflow: hidden;

      img {
        width: 100%;
      }

      span {
        font-size: $small;
        font-weight: $semibold;
      }
    }

    .ad-name {
      margin: 0;
      font-size: $lite;
      font-weight: $medium;
      color: $userTextColor;
    }
  }

  .tip-desc {
    word-break: break-all;
    font-size: 18px;
    height: 70px;
    padding-top: 10px;

    h5 {
      margin: 0 0 5px;
      padding: 0 0 5px;
      text-overflow: ellipsis;
      max-height: 70px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;

    }
  }

  .main-cat {
    font-size: $small;
    font-weight: $semibold;

    .sub-cat {
      font-size: $lite;
      border-radius: 10px;
      padding: 2px 7px;
      border: 1px solid #A6A6A6;
      color: #A6A6A6;
      font-weight: 500;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.calendar-icon {
  background: $primarygradient;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
}

.react-datepicker-wrapper {
  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.react-datepicker {
  font-family: 'Montserrat', sans-serif
}

@media only screen and (max-width: 575px) {
  .manage-tip {
    grid-template-columns: repeat(1, 1fr);
  }

  .tip-content {
    .blog-header {
      align-items: flex-start;
      
      .updated-badge {
        display: none;
      }

      .genius-talk-heading {
        flex-direction: column;
        gap: 5px;

        .updated-badge {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 361px) {
  .tip-content {
    .blog-header {
      .genius-talk-heading {
        .sub-cat {
          display: block;
        }
      }
    }
  }
}