@media only screen and (max-width: 1560px) {
    .user-card-view {
        grid-template-columns: repeat(3, 1fr);
    } 
    .account-pages {
        .login-bg {
            padding: 40px;
         .welcome-content{
             bottom: 70px;
          }
        }
    }
}

@media only screen and (max-width: 1290px) {
    .user-card-view {
        grid-template-columns: repeat(2, 1fr);

    }
}

@media only screen and (max-width: 998px) {

    .account-pages {
        padding: 40px 0;
        .auth-logo{
             img{
                width: 200px;
             }
           }
    }
    #page-header-search-dropdown{
        display: none;
    }
    
    .settings {
        .addressInfo {
            display: none;
        }
    
        .addressInfo-res {
            display: block;
        }
    }
}
 

@media only screen and (max-width: 760px) {
    .user-card-view {
        grid-template-columns: repeat(1, 1fr);

        .user-details {
            .user-info {
                flex-direction: column;

                .user-bio {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }
    }
    .title-grp {
        flex-direction: column;
        align-items: self-start;
        gap: 15px;
    }
 
}

@media only screen and (max-width: 600px) {
    .filter-section {
        flex-direction: column;
        align-items: end;
        margin-bottom: 0px !important;

        .action-field {
            width: 100%;
            margin-bottom: 10px;
        }

        .field {
            width: 50%;
        }
    }

   .progress-step {
        flex-direction: column;

        .step {
            width: 100%;
            padding-bottom: 40px;

            &::before {
                left: 28px;
                width: 5px;
                height: 100%;
            }
            &:last-child{
                width: 100%;
            }
        }

        .content {
            display: flex;

            span {
                margin-right: 25px;
            }
        }
    }
   .pagination{
        max-width: 150px;
        margin: 0 auto;
    }

    .admins-list {
        .filter-section {
            align-items: flex-start;

            .action-field {
                .search-box {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 530px) {
    .booking {

        .status-list {
            .day-date {
                width: 20px;

                p {
                    display: none;
                }
            }

            .day-date-mobile {
                width: 100%;
                display: block;
            }

            .status {
                .status-details {
                    .title {
                        display: flex;

                        span {
                            display: block;
                        }
                    }
                }

                .time {
                    width: 20px;

                    p {
                        display: none;
                    }
                }
            }
        }
    }

    .error-screen{ 
        .account-pages{
            .action-btn{
                flex-direction: column;
                div{
                    margin-bottom: 15px ;
                   a{
                        width: 100%;  
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .profile-screen {
        .pwd-change {
            margin-top: 25px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .filter-section {
        .action-field {
            flex-direction: column;
            margin-bottom: 0px;

            .field {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .filter-section{
        .drop-down {
            margin-right: 0;
            margin-bottom: 20px;
        }
        .user-dropdown {
            [class*="-control"] {
                width: 100%;
            }
        }
    }
    
}

//sateesh styles 


@media only screen and (max-width: 360px) {
    .manage-card {
      .card-body {
        
        .ps-3{
            padding-left: 0.5rem!important;
        }
      
    }
  }
}
.page-content {
    @media (max-width: 768px) {
      .manage-card {
        .card-body {
             .subcat-header   {
          flex-direction: column;
          text-align: left;
  
          .p-1 {
            margin-bottom: 10px; 
          }
        }
          .p-5 {
            padding: 1rem !important;
          }
  
          .card-details {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
          }
        }
       
      }
    }
  }


//dashboard
@media  only screen and (max-width: 576px) {
    .dashboard-page{
        .card-design{
            grid-template-columns: repeat(1, 1fr);
        }
    }

}

@media only screen and (min-width: 580px) and (max-width: 1180px) {
    .dashboard-page{
        .card-design{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

//advisor card
@media (max-width: 480px) {
    .view-advisor {
        .manage-card {
            .crd-details {
                display: grid;
                grid-template-columns: 1fr; 
                padding-bottom: 20px;
                }
        }
    }
    .advisor-body {
        .btn-common {
            min-width: 100px;
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 726px) {
    .view-advisor {
        .manage-card {
            .crd-details {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    padding-bottom: 20px;
            }
        }
    }
}

@media only screen and (min-width: 727px) and (max-width: 1024px) {
    .manage-card {
        .card-body {
            .card-details {
                display: grid;
                grid-template-columns: repeat(2, 1fr); 
            }
        }
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .manage-card {
        .card-body {
            .card-details {
                display: grid;
                grid-template-columns: repeat(3, 1fr); 
            }
        }
    }
}

@media only screen and (min-width: 1281px) {
    .manage-card {
        .card-body {
            .card-details {
                display: grid;
                grid-template-columns: repeat(3, 1fr); 
            }
        }
    }
}
