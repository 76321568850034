
.confirmation {
     .modal-content {
        padding: 20px 25px;
        @include borderRadius_15;
        .modal-header{
            .close-icon{
                position: absolute;
                right: 15px;
                top: 15px;
                cursor: pointer;
                .bx {
                    font-size: $xxxlarge;
                }
            }
        }
        .primary-button{
            margin-right: 15px;
        }
    }
    .head-item{
        @include justify-between;
        width: 100%;
    }
}

@media (min-width: 576px) {
    .confirmation {
        &.modal-sm {
            --bs-modal-width: 460px;
        }
        .form-group{
            margin: 15px 0;
            .form-control{
                background: $content-bg;
            }
        }
    }

}