.progress-step {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    // padding: 27px 0;
    position: relative;
    width: 100%;

    .step {
        width: 32%;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 20px;
            top: 30px;
            width: 100%;
            height: 5px;
            background: #DADADA;
            z-index: 0;

        }

        &:last-child {
            padding-bottom: 0;
            &::before {
                display: none;
            }
        }
        .content {
            margin: 0;
            padding: 0;
            text-align: left;
            position: relative;
            z-index: 1;
            
            span {
                width: 61px;
                height: 61px;
                // background: #FFB1AC;
                background: #DADADA;
                display: block;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
        
                img {
                    padding: 6px;
                    border-radius: 50px;
                    background: #DADADA;
                }
                
            }
    
            p {
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                white-space: wrap;
            }
    
            .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                margin-top: 16px;
            }
        }
        &.active{
            &::before {
            background:  $primary-color;
            }
            .content {
                span {
                    background: $primary-light;
                    img {
                        background: $primary-color;
                    }
                }
            }
        }
        &.complete{
            &::before {
                background:  $primary-color;
                }
                .content {
                    span {
                        background: $primary-light;
                        img {
                            background: $primary-color;
                        }
                    }
                }
            // &::before {
            //     background: $badge-color-color;
            //     }
            //     .content {
            //         span {
            //             background: $active-color;
            //             img {
            //                 background: $badge-color-color;
            //             }
            //         }
            //     }
        }
        &:last-child{
            width: auto;
        }
    }

   

   
}