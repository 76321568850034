// 
// _table.scss
// 

.table {
  tr {
    border: 0;
  }

  th {
    font-weight: $font-weight-bold;
    border: 0px;
    border-bottom: 1px solid $border-color-line;

    &:first-child {
      padding-left: 20px !important; 
    }

    div {
      span {
        position: relative;
        font-size: 14px;
        font-weight: 500;
        color: $dark-grey;

        &::after {
          border: 5px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: -16px;
          top: 0;
          position: absolute;
          width: 0;
        }
      }

      span.desc {
        &::after {
          border-bottom-color: #666;
          top: 2px;
        }
      }

      span.asec {
        &::after {
          top: 8px;
          border-top-color: #666;
        }
      }

    }
  }

  td {
    border: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: #838383;
    padding-left: 0 !important;
    background-color: $whiteColor;
    &:first-child {
      padding-left: 27px !important;
    }

    &.table-details {
      padding: 0 !important;
    }

    &.arrow-icon {
      width: auto;
    }

    .action-filed{
      width: 100%;
      text-align: center;
      min-width: 126px;
    }
    span {
      text-align: center;
      
      &.status {
        display: block;
        width: 100%;
        border-radius: 50px;
        padding: 4px 8px;
        color: white; 
      }
      &.none{
        color: unset; 
        // background: $border-color-line;
      }
      &.draft {
        background-color: $primarygradient;
      }

      &.submitted {
        background-color: $sucess-color; 
      
      }
      &.completed {
        background-color: $badge-color-color; 
      
      }
      &.re-submit{
        background: $dark-primary-icon; 
      }
      &.request{
        background: $cyan;
      }
      &.review{
        background: $primary-light;
      }
    }
  }

  .sub-table {
    padding-left: 60px;
    background: $sub-table;
    position: relative;
    

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: $dark-grey;
      padding: 20px 0px;
      border-bottom: 1px solid $border-color;
      text-align: center;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: $dark-grey;
      text-align: center;
    }
  }

}



.table-dark>:not(:last-child)>:last-child>* {
  border-bottom-color: $table-dark-border-color;
}


// .table-bordered {
//   border: $table-border-width solid $table-border-color;
// }

.table-nowrap {
th{
  padding: 30px;
}
  th,
  td {
    white-space: nowrap;
    background-color: $whiteColor;
  }
}

.table>:not(:first-child) {
  border-top: 0;
}
.accordion-style {
  .card{
    .card-body{
      padding: 0;
      .table-filter-section{         
          padding: 25px;
          padding-bottom: 0;   
          display: flex;
    align-items: center;     
      }
    }
  }
  th{
    &:first-child{
      border: 0;
    }
    &:last-child{
      width: 30px;
      border-bottom: 0;
    }
  }
}

