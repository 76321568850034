// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  // box-shadow: $box-shadow;
}
ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

// Manage Card Stayle
.manage-card {
  .profile {
    @include box-size(35px, 35px);
    @include borderRadius_50;
    background: #DBE8F0;
    @include flex-center;
    position: relative;
    .active-status{
      @include box-size(10px, 10px);
      @include borderRadius_50;
      position: absolute;
      bottom: 0px;
      right: 0px;
      border: 1px solid $whiteColor;
    
   }

   .profile-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

      img {
        width: 100%;
        object-fit: fill;
        height: 100%;
        border-radius: 50%;
      }
    }
   

   &.active{
    border:1px solid $active-color;
    .active-status{
      background:$active-color;
    }
   }
   &.inactive{
    border:1px solid $deactive-color;
    .active-status{
      background:$deactive-color;
    }
   }
   &.incomplete{
    border:1px solid $yellow;
    .active-status{
      background:$yellow;
    }
   }
    
  }
  hr{
    margin: 0;
    border-top: 1px solid rgba(225, 225, 225, 0.50);
  }
.crd-details {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  span  {
      font-size: $lite;
      font-weight: $regular;
      color: #999;
    }
  p {
      font-size: $fs-medium;
      font-weight: $medium;
      color: #2A3547;
      margin: 0;
    }
}
}

.more-option {
  background: #F2F5F7;
  border: 0;
  color: #000;
  font-size: 20px;
  padding: 5px;
  @include box-size(30px,30px);
  @include borderRadius_5;
  @include flex-center-vert;
  &:hover{
    color: #000;
    background: #F2F5F7 !important;
  }
}

.self-end{
  justify-self: flex-end;
  padding-right: 10px;
}
// Blog Card Style
.thumbnail-img{
 
  position: relative;
  img{
    object-fit: cover;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 100%;
    height: 100%;
  }
}
.tip-card{
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 15px;
  .tip-group{
   position: relative;
  }
&.inactive{
  opacity: 0.6;
}
}


.loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #FFF;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  &::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted #FF3D00;
    border-style: solid solid dotted;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }
}

    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 