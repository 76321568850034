.select-search-container{
    .select-search-input{
        border-color: $white !important;
        border-radius: 10px;
        
    }
   
    .select-search-options{
        .select-search-row{
            button{
                padding: 6px 12px;
                font-size: 13px;
            }
        }
    }
    &:hover{
        border-color: $white;
    }
    .select-search-select{
        border: 0;
        li{
            button{
                &:hover{
                    background: $primarygradient !important;
                    color: $white !important;
                }
                &.select-search-is-selected{
                    background: $primarygradient;
                    color: $white;
                }
            }
        }
    }
}

.select-down-arrow {
    .bx-chevron-down{
        border : 0;
    }
}