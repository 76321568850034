.multiSelectContainer {
    cursor: pointer;
    position: relative;
    .search-wrapper{
        cursor: pointer;
        background: white;
        box-shadow: 1px 2px 3px #d5d5d5;
        border: 0;
    } 
    
    .singleChip{
        padding: 2px 10px;
        margin: 0;
        img{
            display: none;
        }
    }
    li{
        background: white;
        color: black;
        padding: 4px 10px;
        &.selected{
            background-color: $primary;
            color: white;
        }
        &:hover{
            background: #ededed;
            color: black;
        }
    }
    .icon_down_dir{
        display: none;
    }
    &::before{
        content: '';
        position: absolute;
        top:10px;
        right: 16px;
        width: 10px;
        height: 10px;
        z-index: 2;
        border-top: 2px solid black;
        border-right: 2px solid black;
        transform: rotate(135deg);
        border-top-right-radius: 4px;
    }
}

body[data-layout-mode="dark"] {
    .multiSelectContainer {
        .search-wrapper{
            background: #4B506A;
            box-shadow: none;
        }
        ul{
            border: none;
        }
        li{
            background: #4B506A;
            color: white;
            &:hover{
                background: $primary-color;
            }
            &.selected{
                background: $primary-color;
            }
        }
        &::before{
            border-color: white;
        }
        ::placeholder{
            color: white;
        }
    }
}