
.category-screen{

.user-card-view {
    .title-grp{
      @include justify-between;
      h2{
        padding-bottom: 0;
      }

    }
    .manage-card {
      .profile{
        .active-status{
          bottom: 5px;
          right: 1px;
        }
          @include box-size(55px, 55px);
          span{
              font-size: $xxlarge;
              font-weight: $semibold;
          }
      } 
      .card-details {
        display: grid;
        gap:1rem;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 35px;
        .btn-common{
          min-width: 90px;
        }
        
    } 
     
  }

}
.profile-image {
    border-radius: 50%; 
    width: 50px; 
    height: 50px; 
    background: #ccc;
}

.modal-body{
    .text-input{
        text-align: left;
    }
}

}

// .subcategory-screen

.subcategory-screen{
  .title-grp{
    @include justify-between;
    h2{
      padding-bottom: 0;
    }
  }
  .manage-card {
    .subcat-content{
      display: flex;
      justify-content: space-between;
      align-items:flex-start;
      .profile{
        .active-status{
          bottom: 5px;
          right: 1px;
        }
        @include box-size(55px, 55px);
        span{
            font-size: $xxlarge;
            font-weight: $semibold;
        }
    }
    } 
    .card-details {
    display: grid;
    gap:1rem;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 35px;
    
    &.empty {
      grid-template-columns: unset;
    }

    .subcat-details{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  .card-design{
    border: 1px solid #EDEEEF;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  }
  }
  .profile-image img {
    border-radius: 50%; 
    width: 50px; 
    height: 50px; 
}

}

.profile-image.cate-icon {
  width: 50px;
  height: 50px;
  background: #ccc;
  border-radius: 50%;
}



